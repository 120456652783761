.divConteinerFormContactParcelas{
    padding: 3%;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color:rgb(53, 52, 52);
  }
  
  .divConteinerFormContactParcelas h1{
    margin-bottom: 2%;
    font-size: 50px;
  }
  
  .conteinerFormContactParcelas{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  
  
  .conteinerFormContactParcelas div > input{
    padding: 1%;
    font-size: x-large;
    width: 740px;
    height: 70px;
    margin: 1%;
   
    
    
  }
  .conteinerFormContactParcelas input:focus , textarea:focus{
    outline: none;
  }
  
  .conteinerFormContactParcelas select{
    font-size: x-large;
    margin-top: 1%;
    height: 70px;
    width: 80%;
    padding-left: 2%;
    outline: none;
  }
  
  .conteinerFormContactParcelas  textarea{
    padding: 2%;
    width: 80%;
    font-size: x-large;
    margin-top: 2%;
    
  }
  
  .btnFormContactParcelas{
    font-size: x-large;
    margin-top: 2%;
    width: 50%;
    height: 70px;
    color:white;
    text-transform: uppercase;
    border-style: none;
    background-color: rgb(6, 6, 6);
    border-radius: 6px;
    transition: 1s;
  }
  
  .btnFormContactParcelas:hover{
    cursor: pointer;
    color:black;
    background-color: white;
    transition: 1s;
  }


  @media (max-width: 50.625em){
    .conteinerFormContactParcelas select{
      width: 80%;    
    }

    .conteinerFormContactParcelas div > input{
      width: 270px
    }
  }
