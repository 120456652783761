/* _______________styles carrusel homeHeader___________ */












.conteinerCarruselHeader {
    width: 100%; 
  
  }
  
  .conteinerCarruselHeader img{
    width: 100%;
    height: 800px;
    }
  
  .divImgCarruselHeader{
    position: relative; 
   
  }
  
  
  .divDetailsCarruselHeader{ 
    color: white;
    text-transform: uppercase;
    position: absolute;
    top:40%;
    left: 35%;
    width: 30%;
  }
  
  .divDetailsCarruselHeader h1{
    font-size:60px;
  } 

  .btmCarruselHeader:hover{
    color: black;
    background-color: white; 
    transition: 1s;
  }
  .btmCarruselHeader{
    font-size: xx-large;
    margin-top: 3%;
    color: #fff;
    width: 60%;
    height: 80px;
    text-transform: uppercase;
    border-radius: 6px;
    border-style: none;
    background-color: black; 
  }

  .btmCarruselHeader:hover{
    cursor: pointer;
  }
  
  @media (max-width: 50.625em) {
    .conteinerCarruselHeader img{
      height: 600px;
      }
    

    .divDetailsCarruselHeader h1{
      font-size:40px;
    }

    
    .divDetailsCarruselHeader{
      top:25%;
      left: 25%;
      width: 50%;
    }
    .btmCarruselHeader{
      font-size: x-large;
      height: 70px;
    }

  }

  @media screen and (max-width: 700px) {
    .container-slider{
      margin: 100px 10px 0;
    }
  }