.divConteinerContact{
    display: flex;
  }
  
  .divImgContact{
    background-image: url("../image/imageParallax.jpg") ;
    background-repeat: no-repeat;
      background-size: cover;
    height:800px;
    width: 50%;
  }
  
  .conteinerFormContact{
   
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1%;
    padding-top: 4%;
    padding-bottom: 4%;
    width: 35%;
    height:800px;
    background-color:white
  }
  
  .conteinerFormContact h1{
    margin-bottom: 2%;
    font-size: 50px;
  }
  
  .divInputNombreApellidoFormContact{
  display: flex;
  width: 100%;
  padding-left: 4%;
  padding-right: 4%;
  }
  
  
  
  .conteinerFormContact input{
    width: 90%;
    height: 60px;
    font-size: x-large;
    padding: 2%;
    margin: 1%;
    
  }
  
    input:focus , textarea:focus{
    outline: none;
  }
  
  .conteinerFormContact textarea{
    padding: 2%;
    width: 90%;
    font-size: x-large;
    margin-top: 1%;
    margin-bottom: 2%;
    
  }
  
  .btnFonteinerFormContact{
    font-size: x-large;
    max-width: 609px;
    color:white;
    text-transform: uppercase;
    border-style: none;
    background-color: black;
    border-radius: 6px;
    
    
  }
  
  .btnFonteinerFormContact:hover{
    cursor: pointer;
    background-color:rgb(53, 52, 52);
  
  }

  @media (max-width: 1330px) {

    .divConteinerContact{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .divImgContact{
      height:500px;
      width: 100%;
    }

    .conteinerFormContact{

      width: 100%;
 
    }

 
}

@media (max-width: 800px) {

  .divImgContact{
    height:400px;
  
  }

  .divInputNombreApellidoFormContact{
    display:block ;
    padding: 0%;
   
    }

    .btnFonteinerFormContact{
      margin-top: 5%;
      max-width: 90%;
    }
}