@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
.App {
  text-align: center;
  
}

body{

  font-family: 'Playfair Display', serif;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} 
