/* ___________styles of Parcela___________ */

/* contenerdores de la imagen de las parcelas */
.conteinerParcela {
  display: flex;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height:800px;
  
}

.conteinerParcelaSantajulia {
    display: flex;
    background-image: url("../image/haciendaSantaJulia/11.jpg") ;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    height:800px;
    
  }

.conteinerParcelaDelToro {
  display: flex;
  background-image: url("../image/valleDelToro/11.jpg") ;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height:800px;
  }

 .conteinerParcelaElEncanto {
  display: flex;
  background-image: url("../image/elEncantoDeCuracavi/5.jpg") ;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height:800px;
  } 

 .conteinerParcelaElEncantoII {
  display: flex;
  background-image: url("../image/elEncantoDeCuracaviII/3.jpg") ;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height:800px;
  } 

 .conteinerParcelaElEncantoLoreto {
  display: flex;
  background-image: url("../image/elEncantoDeLoreto/1.jpg") ;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  height:800px;
  } 


/* ________________________________________________________ */
  .conteinerParcelaPrecio{
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .conteinerParcelaPrecio p{
  color: white; 
  text-transform: uppercase;
  font-size: xx-large;
  }
  
  .conteinerParcelaPrecio div{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:green;
    width: 50%;
    padding: 3%;
    margin: 2%;
    border-radius: 6px;
    
    
   
  }
  
  .conteinerParcelaTitle {
    padding: 5%;
    width: 100%;
    height: 68%;
  }
  .conteinerParcelaTitle hr{
    color: black;
    border-style:solid;
  }
  .conteinerParcelaTitle  h1  {
    font-size: 100px;
    text-transform: uppercase;
  }
  
  .conteinerParcelaTitle  h4  {
    font-size: 30px;
    
  }
  .conteinerParcelaTitle  p  {
   
    font-size: x-large;
    margin-top: 3%;
    
    
  }
  
  
  .conteinerParcelaInfo{
    padding: 1%;
    color: #fff;
    font-size: large;
    display: flex;
    background-color: black;
    height:256px;
    width: 100%;
    justify-content: space-evenly;
  }
  .conteinerParcelaInfo p{
    margin: 0%;
  }
  
  .conteinerParcelaInfo div img{
    height: 60%;
  }
  
  .conteinerParcelaImageGaleryTitle{
    display: flex;
    margin : 3%;
  }
  
  .conteinerParcelaImageGaleryTitle h1{
    font-size: 100px;
    text-transform: uppercase;
    padding-right: 5%;
    
  }

  
  .conteinerParcelaImageGaleryTitle  p  {
    font-size: x-large;
    text-align: start; 
  }
  
  .conteinerParcelaImageGaleryTitle div {
   padding-left: 5%;
   border-left-style: solid;
   border-width: 1px;
  
  }
  
  .GaleryImageParcel {
   margin-bottom: 2%;
  }
  
  .GaleryImageParcel img{
    width: 500px;
    height: 500px;
    margin: 1%;
    transition: all 0.8s ease;
    border-radius: 2%;
  }
  
  .GaleryImageParcel img:hover{
    transition: all 0.6s ease;
    transform: scale(1.1);
    filter: brightness(60%);
    cursor: pointer;
    
  }

  .conteinerCarruselGaleryImage{
    display: none;
  }

  @media (max-width: 1390px){
    .conteinerParcelaSantajulia ,.conteinerParcelaDelToro, .conteinerParcelaElEncanto,
    .conteinerParcelaElEncantoII, .conteinerParcelaElEncantoLoreto {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height:auto;
    }
     
  .conteinerParcelaPrecio p{
    font-size: x-large;
  }

  .conteinerParcelaTitle p{
    padding: 0%;
    font-size:large;
    
  }




  @media (max-width: 1080px){
    .conteinerParcelaSantajulia ,.conteinerParcelaDelToro, .conteinerParcelaElEncanto,
    .conteinerParcelaElEncantoII, .conteinerParcelaElEncantoLoreto  {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height:auto;
    }
    
  
    
    }
    .conteinerParcelaPrecio{
      height: 350px;
      width: 100%;
    }

    .conteinerParcelaTitle  h1  {
      font-size: 80px;
      text-transform: uppercase;
    }
    
    .conteinerParcelaTitle  p  {
      font-size: x-large;  
    }
  
   
    }

    @media (max-width: 900px){

    }
  

  @media (max-width: 50.625em){

    .conteinerParcelaInfo{
    
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    .conteinerParcelaImageGaleryTitle{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    
    }

    .conteinerParcelaTitle  h1  {
      font-size: 60px;
     
    }

    .conteinerParcelaImageGaleryTitle h1{
      font-size: 60px;
      padding-right: 0%;
      
    }

    .conteinerParcelaImageGaleryTitle div {    
      border-top-style: solid;
      border-left-style: none;    
     }
    
     .conteinerParcelaInfo div img{
      width:25% ;
     }

     .conteinerParcelaInfo p{
      font-size:x-large;
    }

    .GaleryImageParcel {
      display: none;
    }



    .conteinerCarruselGaleryImage{
      padding: 10%;
      display: block;
    }

    .conteinerCarruselGaleryImage img{
      height: 500px;
     padding: 5%;
    }
  }


