/* ___________styles of Quienes somos___________ */


  .divInfoQuinesSomos{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
  
  .divInfoQuinesSomos p{
    font-size: xx-large;
    margin-top: 1%;
    margin-bottom: 2%;
  }
  
  .divInfoQuinesSomos li{
    font-size: xx-large;
  }

  .divInfoQuinesSomos h1{
    margin-top: 6%;
    font-size: 600%;
    text-transform: uppercase;
  }
  
  .divImgQuinesSomos img{
    width: 100%;
    height: 1000px;
  }
  
  .divImgParallaxQuinesSomos{
    width: 100%;
    height: 500px;
    background-image: url("../image/imageCarouselTwo.jpg") ;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }

  .formContactQuienesSomos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color:rgb(53, 52, 52);
    color: white;
    display: flex;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .formContactQuienesSomos h1{
    font-size: 50px;
  }

  .conteinerInputFormContactQuienesSomos{
    width: 100%;
  }

  .conteinerInputFormContactQuienesSomos input{
    padding: 2%;
    font-size: x-large;
    width: 45%;
    margin: 1%;
    height: 70px;
    
  }

  .formContactQuienesSomos textarea{
    padding: 2%;
    font-size: x-large;
    width: 92%;
    margin-top: 1%;
    margin-bottom: 2%;
  }

  .formContactQuienesSomos input:focus , textarea:focus{
    outline: none;
  }


  .btnFormContactQuienesSomos{
    font-size: x-large;
    width: 50%;
    height: 70px;
    color:white;
    text-transform: uppercase;
    border-style: none;
    background-color: black;
    border-radius: 6px;
    transition: 1s;
  }
  
  .btnFormContactQuienesSomos:hover{
    cursor: pointer;
    color:black;
    background-color: white;
    transition: 1s;
  }


  @media (max-width: 50.625em) {

    .divInfoQuinesSomos h1{
      font-size: 300%;  
    }

    .divInfoQuinesSomos p{
      font-size: large;
    }
    
    .divInfoQuinesSomos li{
      font-size: x-large;
    }

    .divInfoQuinesSomos ul{
      margin-top: 10%;
    }

    .divImgQuinesSomos img{
      width: 100%;
      height: 500px;
    }

    .conteinerInputFormContactQuienesSomos input{
      width:92%;      
    }

    .btnFormContactQuienesSomos{
      width: 92%;
      margin-top: 5%;
      margin-bottom: 15%;
    }
  }