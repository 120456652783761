/* ___________styles of vende tu campo____________ */

.headerVendeTuCampo{
    /* background-image: url("../image/vendetucampo.jpg") ; */
    height: 700px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
  }
  
  .divTitleFormVendeTuCampo{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
  }
  
  .divTitleFormVendeTuCampo h1{
    font-size: 400%;
    text-transform: uppercase;
  }
  
  .divTitleFormVendeTuCampo p{
    font-size: xx-large;
    margin-top: 1%;
  }
  .divConteinerFormContactVendeTuCampo{
    padding: 3%;
    color: #fff;
    width: 100%;
    height: 100%;
    background-color:rgb(53, 52, 52);
  }
  
  .divConteinerFormContactVendeTuCampo h1{
    margin-bottom: 2%;
    font-size: 50px;
  }
  
  .conteinerFormContactVendeTuCampo{
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }

  .conteinerFormContactVendeTuCampo div > input{
    width: 750px;
    height: 70px;
    margin: 1%;
    font-size: x-large;
    padding: 2%;
    
  }
  .conteinerFormContactVendeTuCampo input:focus , textarea:focus{
    outline: none;
  }

  .conteinerFormContactVendeTuCampo  textarea{
    width: 81%;
    font-size: x-large;
    margin-top: 2%;
    
  }
  
  .btnFormContactVendeTuCampo{
    font-size: x-large;
    margin-top: 2%;
    width: 50%;
    height: 70px;
    color:white;
    text-transform: uppercase;
    border-style: none;
    background-color: black;
    border-radius: 6px;
    transition: 1s;
  }
  
  .btnFormContactVendeTuCampo:hover{
    cursor: pointer;
    color:black;
    background-color: white;
    transition: 1s;
  }
  @media (max-width: 50.625em) {

    .headerVendeTuCampo{
      height: 450px;
    }
    
    .divInputForm input{
      width: 300px;
    }
    
    .divTitleFormVendeTuCampo h1{
      font-size:50px;
    }

    .divTitleFormVendeTuCampo p{
      font-size:30px;     
    }

    .conteinerFormContactVendeTuCampo div > input{
      width: 92%
    }

    .btnFormContactVendeTuCampo{
      width: 92%;
      margin-top: 5%;
      margin-bottom: 15%;
    }

    .conteinerFormContactVendeTuCampo  textarea{
      width: 92%
    }
  }
 

 