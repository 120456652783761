  .divTitle{
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
    padding-bottom: 3%;
  }

  .divTitle h1{
    font-size: 600%;
    text-transform: uppercase;
  }

  .divTitle a{
    text-decoration: none;
  }

  .header {
    background-color: black;
  }

  .divVideo{
    height: 700px;
  }

  .divImg{
    display: flex;
    justify-content: space-evenly;
  }

  .divImg img{
   width: 370px;
   margin-bottom: 2%;
  }

  .btnFonteinerFormContact{
    font-size: x-large;
    width: 100%;
    height: 100px;
    color:white;
    text-transform: uppercase;
    border-style: none;
    background-color: black;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .btnFonteinerFormContact:hover{
    cursor: pointer;
    background-color:rgb(53, 52, 52);
  
  }

  .divDetalleFinanciamiento{
    margin: 1%;
    font-size: 40px;
  }

  @media (max-width: 50.625em){

    .header img{
      width: 80%;;
    }
    
    .divImg {
      display: none;
    }

    .conteinerCarruselGaleryImage{
      padding: 10%;
      display: block;
    }
    
    .divTitle h1{
      font-size: 35px;
    }

    .btnFonteinerFormContact p{
      display: none;
    }

    .divDetalleFinanciamiento{  
      font-size: 20px;
    }
    /* .divVideo{
      width: 100%;
    } */
  }